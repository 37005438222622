import React from "react";
import "./ProductCards.css"; // Import the CSS file for styling

function ProductCards() {
  return (
    <div className="product-container">
      <a href="https://consciousneurons.com/em" className="product-card">
        <div className="product-title">EM</div>
        <p className="product-description">
          Effortless Manifestation Imagine a world where your dreams
          effortlessly materialize into reality.
        </p>
      </a>

      <a href="https://consciousneurons.com/focus" className="product-card">
        <div className="product-title">Focus</div>
        <p className="product-description">
          Cultivate focused attention and mindfulness in our fast-paced digital
          age.
        </p>
      </a>
    </div>
  );
}

export default ProductCards;
