import './App.css';
import ProductCards from './components/ProductCards';

function App() {
  return (
    <div className="App">
      <h1 className='main-heading'>Concious Neurons</h1>  
    {/* <h1 className='sub-heading'>Welcome to Conscious Neurons</h1> */}
      <div className="introduction">
    <p>A platform dedicated to enhancing your mental well-being through innovative applications that empower your mind and elevate your consciousness.</p>
  </div>

  <div className="app-description">
    <div class="app-title">Effortless Manifestation</div>
    <p>Imagine a world where your dreams effortlessly materialize into reality. Our Effortless Manifestation app is your gateway to this realm. Through guided techniques, positive affirmations, and the art of focused intention, this app empowers you to manifest your desires with ease. Whether it's attracting abundance, health, or success, Effortless Manifestation enables you to align your thoughts, energy, and actions, paving the way for a life filled with purpose and fulfillment.</p>
  </div>

  <div className="app-description">
    <div className="app-title">Focused Attention</div>
    <p>In our fast-paced digital age, cultivating focused attention has become a precious skill. Our Focused Attention app is your tool to train and enhance your ability to stay present, concentrate deeply, and increase mindfulness. By offering mindfulness exercises, meditation practices, and concentration-building activities, this app helps you regain control over your attention, fostering clarity, reducing stress, and promoting a sense of calm in the midst of life's demands.</p>
      </div>
      
      <ProductCards/>
      
    </div>
  );
}

export default App;
